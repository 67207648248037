import React from 'react'
import PropTypes from 'prop-types'
import FormBuilder from '@/component/Common/FormBuilder'
import createFormSubmissionContainer from '../../Common/FormBuilder/form-submission'
import buildSchema from './schema'

const FormWidget = ({
  form,
  section,
  downloadButtonText,
  downloadUrl,
  videoId,
  videoProvider
}) => {
  const FormSubmissionContainer = createFormSubmissionContainer({
    Component: (props) => (
      <FormBuilder
        downloadButtonText={downloadButtonText}
        downloadUrl={downloadUrl}
        videoId={videoId}
        videoProvider={videoProvider}
        {...props}
      />
    ),
    form,
    section,
    buildSchema,
    submitUrl: `/api/submit-form/`
  })
  return <FormSubmissionContainer />
}

FormWidget.propTypes = {
  section: PropTypes.object,
  form: PropTypes.array,
  downloadButtonText: PropTypes.string,
  downloadUrl: PropTypes.string,
  videoId: PropTypes.string,
  videoProvider: PropTypes.string
}

export default FormWidget
